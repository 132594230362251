import React from 'react';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';

export const WORKS = [
  {
    when: 'Avril 2022 - Aujourd\'hui',
    where: 'Bedrock Streaming - Software Developer',
    what: "En charge du développement et de la maintenance des applcations sur smartTV et boxTV Orange pour les clients de Bedrock Streaming: 6PLAY, Videoland, RTLmost, RTLplay, Salto...",
  },
  {
    when: 'Décembre 2020 - Avril 2022',
    where: 'Sully Group - Software Developer',
    what: "Consultante en mission chez le client Bedrock Streaming.",
  },
  {
    when: 'Mai 2018 - Février 2020',
    where: 'Lelivrescolaire.fr - Développeuse web',
    what: 'En charge du développement et de la maintenance du site lelivrescolaire.fr, ainsi que les outils numériques développés par la société.',
  },
  {
    when: 'Mars 2018 - Avril 2018',
    where: 'La Source Esport - Développeuse web',
    what: 'En charge de développer des jeux web ainsi que de la migration du site internet de Wordpress à PHP',
  },

];

export const CERTIFICATES = [
  {
    when: 'Avril 2023',
    where: 'Bedrock Streaming',
    what: 'Certificat de réussite - Formation RGPD',
  },
  {
    when: 'Avril 2018 - Juillet 2020',
    where: 'IT Akademy',
    what: 'Titre RNCP 6 Développeur Full Stack - Formation 2 ans en alternance',
  },
  {
    when: 'Décembre 2017 - Février 2018',
    where: 'EPSI - "Graines de Boss Academy"',
    what: 'Formation "Base de la programmation et marketing digital"',
  },
  {
    when: 'Mars 2017',
    where: 'Université Jean Moulin',
    what: 'Certificat de réussite - HSK niveau 4',
  },
  {
    when: 'Septembre 2010 - Juillet 2013',
    where: 'Université Lyon 2',
    what: 'Licence LEA anglais-chinois',
  },
];

export const PROJECTS = [
  {
    'title': 'Border Leeks',
    'play': 'https://le-poulet.itch.io/border-leeks',
    'picture': 'border-leeks-min.jpg',
    'description': 'Puzzle game où il faut gérer les territoires de différents royaumes afin de subvenir à leurs besoins en englobant de nouvelles terres et récupérer les ressources qui s’y trouvent. Le but est de satisfaire tous les royaumes pour éviter les conflits. Jeu réalisé en 48h à l’occasion de la Jamming Assembly 2020.<br/><br/>Équipe composée de: Alexandre Blanchar (Game Design), Alice Dreux (Sound Design), Victor Dulon (Graphisme), Julie Nginn (Programmation), Ralph Nicolas (Programmation)',
    'type': 'game',
    'published': 'novembre 2020',
    'context': 'Game Jam'
  },
  {
    'title': 'Cats Seeker',
    'play': 'https://simmer.io/@Madylune/cats-seeker',
    'picture': 'cats-seeker-min.png',
    'description': 'Jeu de type "hidden object". Le joueur est bénévole d\'une association pour la protection des chats. Il doit chercher des chats abandonnés dans la ville dans un temps limité.',
    'type': 'game',
    'githubID': 315906492,
    'context': 'Perso'
  },
  {
    'title': 'ChronoQuiz',
    'url': 'https://quiz.lls.fr/#/',
    'picture': 'chronoquiz-min.png',
    'description': 'Jeu éducatif qui permet aux professeurs d\'évaluer leurs élèves avec des quiz personnalisés. Après avoir créer des quiz, ils peuvent inviter leurs élèves à rejoindre une session. Ils devront faire preuve de réflexion et de rapidité pour marquer des points. Ce projet a été élaboré au sein du Livrescolaire.fr.',
    'type': 'web',
    'published': 'septembre 2019',
    'context': 'Pro'
  },
  {
    'title': 'Culture Quiz',
    'url': 'https://madylune.github.io/culture-quiz/#/quiz',
    'picture': 'culture-quiz-min.png',
    'description': 'Application web sous le format "serious game". Elle permet aux utilisateurs de tester et d\'entraîner leur culture général avec des QCM. Utilisation de l\'API <a href="https://culture-quiz-api.herokuapp.com/" target="_blank">culture-quiz-api.</a>',
    'githubID': 310100096,
    'type': 'web',
    'context': 'Perso'
  },
  {
    'title': 'Paint Shoot',
    'url': null,
    'picture': 'paint-shoot-min.png',
    'description': 'Jeu multijoueur en ligne de type FPS inspiré du paintball. Chaque équipe est représenté par une couleur (bleu, rouge, vert et jaune). Une équipe gagne lorsqu\'elle a marqué le plus de points possible en tirant sur les autres équipes.',
    'githubID': 300956074,
    'type': 'game',
    'context': 'Perso'
  },
  { 
    'title': 'Animal Rescue',
    'url': 'https://youtu.be/70BdUCCyddw',
    'play': 'https://simmer.io/@Madylune/animal-rescue',
    'picture': 'animal-rescue-min.png',
    'description': 'Casual game inspiré des jeux de plateforme dont le but est de survivre face aux obstacles afin de sauver les animaux. La difficulté de ce jeu le rend plutôt addictif.',
    'githubID': 286291244,
    'type': 'game',
    'context': 'Perso'
  },
  {
    'title': 'Chat Rooms',
    'url': 'https://madylune.github.io/chat-rooms/',
    'picture': 'chat-rooms-min.png',
    'description': "Plateforme de discussion où l'on peut inviter ses amis à discuter dans une salle privée ou bien discuter avec d'autres personnes dans une salle publique. Les salles peuvent être thématisées.",
    'githubID': 294219355,
    'type': 'web',
    'context': 'Perso'
  },
  {
    'title': 'My Fantasy Life',
    'url': 'https://youtu.be/Y6xlcGm-7FU',
    'picture': 'mfl-min.png',
    'description': 'Jeu de type MMORPG. Je me suis inspirée d\'un mélange entre l\'Heroic Fantasy et la culture asiatique pour créer l\'univers de ce jeu. J\'avais l\'idée de démocratiser les rencontres en ligne et les rendre plus ludiques à travers ce jeu en ligne.',
    'githubID': 274091333,
    'type': 'game',
    'context': 'Perso'
  },
  {
    'title': 'Ragnarok Mob Bashing',
    'url': 'https://youtu.be/zG6BOJpr3rg',
    'picture': 'ragnarok-mob-bashing-min.png',
    'description': "Jeu de survie de type Hack'n'Slash. C'est une adaptation du jeu coréen Ragnarok Online développé et édité par Gravity Corp. On y retrouve tous les éléments du jeu original: les personnages, les monstres, les cartes et même les musiques de fond !",
    'githubID': 235530271,
    'type': 'game',
    'context': 'Perso'
  },
  {
    'title': 'Pokemon Memory',
    'play': 'https://madylune.github.io/pokemon-memory/',
    'picture': 'pokemon-min.png',
    'description': "Jeu de carte sur le thème des Pokémon. Il s'agit d'un jeu de mémorisation, toutes les cartes sont retournées, il faut les retourner une par une pour découvrir des pairs.",
    'githubID': 284331924,
    'type': 'web',
    'context': 'Perso'
  },
  {
    'title': 'My Tabata',
    'url': 'https://madylune.github.io/my-tabata/',
    'picture': 'my-tabata-min.png',
    'description': "Application destinée aux sportifs qui s'entraînent avec la méthode Tabata (20s d'effort intense + 10 min de récupération, à répéter 8 fois). On peut créer ses propres programmes et ainsi adapter ses entraînements. On peut paramétrer le temps d'effort, de repos, le nombre d'exercice, le nombre de cycle, ...",
    'githubID': 256512399,
    'type': 'web',
    'context': 'Perso'
  },
  {
    'title': 'Neko\'nect',
    'url': 'https://youtu.be/7YcIm_k5XoU',
    'picture': 'nekonect-min.png',
    'description': 'Peluche connectée à une app web. Elle peut chanter, danser et émettre des émotions. Ce projet a été réalisé lors d\'un hackathon à l\'IT AKADEMY dont le but était de créer un objet connecté.',
    'githubID': 198587789,
    'type': 'web',
    'context': 'Scolaire'
  },
  {
    'title': 'RO Character Simulator',
    'url': 'https://madylune.github.io/ragnarok-char-simulator/',
    'picture': 'ragnarok-char-min.png',
    'description': 'Inspiré du jeu Ragnarok Online. Il s\'agit d\'une reproduction de l\'interface personnage du jeu vidéo. Ce projet a été élaboré dans le cadre d\'une évaluation scolaire.',
    'githubID': 149465839,
    'type': 'web',
    'context': 'Scolaire'
  },
  {
    'title': 'Movie Night',
    'url': 'https://madylune.github.io/MovieNight/',
    'picture': 'movie-night-min.png',
    'description': 'Site qui répertorie les films populaires du moment en utilisant l\'API themoviedb. Ce projet a été élaboré dans le cadre d\'un travail pratique scolaire.',
    'githubID': 158416236,
    'type': 'web',
    'context': 'Scolaire'
  }
];

export const HOBBIES = [
  {
    title: 'Dessin',
    url: 'https://www.deviantart.com/madylune',
    image: 'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/ae05ff03-550f-4527-a9d1-f165550adbd6/dfuhmpn-79f802f8-3c20-48ba-b96a-06c73d91a06a.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcL2FlMDVmZjAzLTU1MGYtNDUyNy1hOWQxLWYxNjU1NTBhZGJkNlwvZGZ1aG1wbi03OWY4MDJmOC0zYzIwLTQ4YmEtYjk2YS0wNmM3M2Q5MWEwNmEucG5nIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.I-UqsK7tIeWgDMkPbIw3BBdPfD8RzZ68xKB1QBbdcOk',
    description: "Le dessin est une passion que je partage avec mes frères et soeurs depuis notre plus tendre enfance.",
    button: 'Deviant Art'
  },
  {
    title: 'Création de jeux vidéo',
    url: 'https://simmer.io/@Madylune',
    image: 'https://github.com/Madylune/cats-seeker/blob/main/cover.png?raw=true',
    description: "J'ai toujours adoré jouer aux jeux vidéo, mais depuis que je sais coder j'adore d'autant plus en créer et les programmer moi-même!",
    button: 'Simmer.io'
  },
  {
    title: 'Déco & bricolage',
    url: 'https://pin.it/3DVMXaH',
    image: 'https://i.pinimg.com/750x/b9/bd/81/b9bd817ace043e14089a04b029a63ad1.jpg',
    description: "Quelle satisfaction de confectionner ses meubles soi-même ! J'aime particulièrement faire de la récup' ;)",
    button: 'Pinterest'
  }
];

export const CONTACT = [
  {
    title: 'LinkedIn',
    link: 'https://www.linkedin.com/in/julie-nginn/',
    type: 'newTab',
    icon: <LinkedInIcon />,
    size: 'M'
  },
  {
    title: 'GitHub',
    link: 'https://github.com/Madylune',
    type: 'newTab',
    icon: <GitHubIcon />,
    size: 'S'
  }
];

export const TALKS = [
  {
    when: 'Avril 2022',
    where: 'Bedrock Streaming',
    what: "Créer un jeu vidéo en moins d'une heure sur Unity",
    url: 'https://tech.bedrockstreaming.com/creer-un-jeu-video-en-moins-dune-heure-sur-unity'
  },
  {
    when: 'Septembre 2023',
    where: 'Bedrock Streaming',
    what: "JSON au service des devs",
    url: 'https://tech.bedrockstreaming.com/json-au-service-des-devs'
  },
];
