import React from 'react'
import styled from 'styled-components'
import Paper from '@material-ui/core/Paper'
import Slide from '@material-ui/core/Slide'
import Modal from '@material-ui/core/Modal'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import GitHubIcon from '@material-ui/icons/GitHub'
import { BREAKPOINTS } from '../theme'
import get from 'lodash/fp/get'
import { getLastUpdate, getRepoUrl } from '../utils'
import Ribbon from './Ribbon'
import Button from './Button'

const StyledPaper = styled(Paper)`
  && {
    position: relative;
    height: 70%;
    width: 60%;
    margin: 5% auto;
    padding: 20px 50px;
    text-align: center;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    @media (max-width: ${BREAKPOINTS.sm}) {
      height: 97%;
      width: 95%;
      margin: 0;
      padding: 10px;
      justify-content: flex-start;
    }
  }
`

const StyledCloseButton = styled(IconButton)`
  && {
    position: absolute;
    top: 0;
    right: 0;
  }
`

const StyledTitle = styled.h2`
  margin: 30px 0 10px;
  @media (max-width: ${BREAKPOINTS.sm}) {
    font-size: 15px;
  }
`

const StyledImg = styled.img`
  max-width: 100%;
  height: 250px;
  margin-bottom: 10px;

  @media (max-width: ${BREAKPOINTS.md}) {
    height: 200px;
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    height: 150px;
  }
`

const StyledDescription = styled.div`
  font-size: 13px;
  margin: 10px 0;
  @media (max-width: ${BREAKPOINTS.sm}) {
    font-size: 10px;
  }
`

const StyledInfos = styled.div`
  align-self: flex-start;
`

const StyledInfo = styled.div`
  margin: 5px 0;
  font-size: 10px;
  display: flex;
  align-items: center;

  svg {
    margin: 0 10px;
  }
  a {
    text-decoration: none;
    color: #2f2f2f;
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    font-size: 9px;
    svg {
      margin: 10px;
    }
  }
`

const StyledDate = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 10px;
  @media (max-width: ${BREAKPOINTS.sm}) {
    font-size: 8px;
  }
`

const ProjectModal = ({ isOpen, project, onClose, repos }) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Slide direction="down" in={isOpen} timeout={500}>
        <StyledPaper>
          <Ribbon context={project.context} left={true} />
          <StyledCloseButton aria-label="delete" onClick={onClose}>
            <CloseIcon />
          </StyledCloseButton>
          <StyledTitle>{get('title', project)}</StyledTitle>
          <StyledImg src={require(`../images/projects/${project.picture}`)} alt={get('title', project)} />
          <StyledDescription><span dangerouslySetInnerHTML={{ __html: get('description', project) }} /></StyledDescription>
          <div>
          {project.url && 
            <Button title="Découvrir" url={project.url} />
          }
          {project.play && 
            <Button title="Jouer" url={project.play} />
          }
          {!project.play && !project.url && 
            <Button title="Bientôt disponible" disable={true} />
          } 
          </div>
          <StyledInfos>
            {project.githubID && (
              <StyledInfo>
                <GitHubIcon />
                <a href={getRepoUrl(repos, project.githubID)} target="_blank" rel="noopener noreferrer">Code source</a>
              </StyledInfo>
            )}
          </StyledInfos>
            <StyledDate>
              {project.githubID ? `Mis à jour ${getLastUpdate(repos, project.githubID)}` : `Publié en ${get('published', project)}`}
            </StyledDate>
        </StyledPaper>
      </Slide>
    </Modal>
  )
}

export default ProjectModal