import React from 'react';
import styled from 'styled-components';
import { BREAKPOINTS } from '../theme'

const StyledTitle = styled.h2`
  text-align: center;
  font-size: 30px;
  color: ${({ color }) => color};

  @media (max-width: ${BREAKPOINTS.sm}) {
    font-size: 20px;
  }
`

export default function Title({ title, color }) {
  return <StyledTitle color={color}>{title}</StyledTitle>
};
