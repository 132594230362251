import React from 'react'
import styled from 'styled-components'
import { BREAKPOINTS } from '../theme'

const StyledHome = styled.div`
  background: url(${require('../images/home-background.png')}) no-repeat;
  background-size: contain;
  background-position: right;
  background-color: #D9D9D9;
  
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: ${BREAKPOINTS.sm}) {
    height: auto;
  }
`

const StyledTitle = styled.div`
  margin: 10px 0 20px;
  text-align: center;

  @media (max-width: ${BREAKPOINTS.sm}) {
    margin-top: 30px;
  }
`

const StyledName = styled.h1`
  color: #000000;
  margin: 10px 0 30px;
  font-size: 60px;

  @media (max-width: ${BREAKPOINTS.sm}) {
    margin: 10px 0;
    font-size: 25px;
  }
`

const StyledSubTitle = styled.h2`
  font-size: 30px;
  color: #383838;

  @media (max-width: ${BREAKPOINTS.sm}) {
    font-size: 20px;
  }
`

const Home = () => 
  <StyledHome id="accueil">
    <StyledTitle>
      <StyledName>Julie Nginn</StyledName>
      <StyledSubTitle>Software Developer</StyledSubTitle>
    </StyledTitle>
  </StyledHome>

export default Home