import React from 'react'
import styled from 'styled-components';
import SchoolIcon from '@material-ui/icons/School';
import WorkIcon from '@material-ui/icons/Work';
import TalkIcon from '@material-ui/icons/HeadsetMic';
import Timeline from '../components/Timeline';
import Title from '../components/Title';
import { CERTIFICATES, WORKS, TALKS } from '../fixtures';

const StyledAbout = styled.div`
  background-color: #383838;
  color: #ffffff;
`

const StyledBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
`;

const About = () => 
  <StyledAbout>
    <StyledBlock id="works">
      <Title title="Expériences professionnelles" />
      <Timeline items={WORKS} pictoColor="#b18a57" picto={<WorkIcon />} />
    </StyledBlock>
    <StyledBlock id="certificates">
      <Title title="Certifications" />
      <Timeline items={CERTIFICATES} pictoColor="#26a69a" picto={<SchoolIcon />} />
    </StyledBlock>
    <StyledBlock id="talk">
      <Title title="Conférences" />
      <Timeline items={TALKS} pictoColor="#2677a6" picto={<TalkIcon />} />
    </StyledBlock>
  </StyledAbout>

export default About