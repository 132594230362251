import find from 'lodash/fp/find'
import get from 'lodash/fp/get'
import moment from 'moment'
import 'moment/locale/fr'

export const mobileDevice = window.innerWidth <= 768

export const getLastUpdate = (repos, githubID) => {
  const timestamp = get('pushed_at', find({ id: githubID }, repos))
  const date = timestamp && new Date(timestamp)
  moment.locale('fr')
  return date && moment(date).startOf('hour').fromNow(); 
}

export const getRepoUrl = (repos, githubID) => get('html_url', find({ id: githubID }, repos))

export const getContextColor = context => {
  switch (context) {
    case 'Perso':
      return '#65ffb2'
    case 'Pro':
      return '#7acaff'
    case 'Scolaire':
      return '#ffbd7b'
    case 'Game Jam':
      return '#dc97ff'
    default:
      return '#999'
  }
}